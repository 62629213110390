import { Link } from "@inertiajs/react";
import React from "react";

const Breadcrumbs = ({ breadcrumbs }) => {
    if (breadcrumbs.length == 0) {
        return (
            <nav className="breadcrumbs mb-14">
                <span title='Dashboard' className="text-black font-bold font-16">
                    Dashboard
                </span>
            </nav>
        )
    }

    return (
        <nav className="breadcrumbs mb-14">
            <Link href={route('dashboard')} title='Dashboard' className="text-black hover:text-yellow transition-all ease-in-out duration-150 font-bold font-16">
                Dashboard
            </Link>
            {breadcrumbs.map((el, id) => {
                if(el.current === true){
                    return (
                        <React.Fragment key={id}>
                            <span className="text-blue-classic font-bold font-16"> / </span>
                            <span className="text-blue-classic font-bold font-16">{el.title}</span>
                        </React.Fragment>
                    )
                }
                return (
                    <React.Fragment key={id}>
                        <span className="text-black font-bold font-16"> / </span>
                        <Link key={id} href={el.href} title={el.title} className="text-black hover:text-yellow transition-all ease-in-out duration-150 font-bold font-16">
                            {el.title}
                        </Link>
                    </React.Fragment>
                )
            })}
        </nav>
    );
}

export default Breadcrumbs;