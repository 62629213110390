import Links from '@/Components/System/Nav/LinksArray'
import { Icon } from '@iconify/react';
import NavLink from '@/Components/System/Nav/NavLink';

const NavMenu = () => {

  
  return (
    <div className='nav-menu md:mt-[88px] flex flex-col gap-y-2'>
      <NavLink key={Links[0].url} href={Links[0].url}>
        <Icon icon={Links[0].icon} height="24" width="24" />
        {Links[0].text}
      </NavLink>

      <div className='separator px-12 mt-[53px] mb-[29px] text-15 font-bold text-white text-opacity-[27%]'>
          Zarządzanie
      </div>

      {Links.slice(1).map(link => (
          <NavLink key={link.url} href={link.url}>
            <Icon icon={link.icon} height="24" width="24" />
            {link.text}
          </NavLink>
      ))}
    </div>
  );
}

export default NavMenu;