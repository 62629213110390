import { Link } from '@inertiajs/react';

const NavLink = ({ href, className = '', children }) => {

  const checkIfActive = () => {
    if (route().current(href +'.*') || route().current() === href) {
      return true
    } 
    return false
  }

  return (
    <div className={`last:mb-8 ${checkIfActive() ? 'relative before:bg-white before:content-[""] before:w-[calc(100%_-_30px)] before:right-0 before:rounded-l-20 before:h-full before:block before:absolute before:z-0 ' : ''}` + className}>
      <Link
        href={route(href)}
        as="button"
        className={`z-10 relative transition-all ease-in-out duration-150 font-bold w-full px-12 py-3 flex gap-2.5 items-center mt-auto ${checkIfActive() ? 'text-darker-blue hover:text-blue-classic' : 'hover:text-yellow text-white'} `}
      >
        {children}
      </Link> 
    </div>
  );
}

export default NavLink;