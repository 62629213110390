import { usePage } from "@inertiajs/react";
import { useEffect, useState } from "react";
import { Icon } from '@iconify/react';
import { Transition } from "@headlessui/react";

const SessionMessage = () => {
    const [visible, setVisible] = useState(true);
    const { error, success } = usePage().props?.flash;

    return (
        <Transition
            show={visible}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
                <div className={`flex p-2 border-3 rounded-20 my-6 items-center ${success && 'border-lime'} ${error && 'border-danger'}`}>
                    {success ? 
                        <>
                            <Icon icon="mdi:tick-circle-outline" width={25} height={25} className="text-lime mr-3"/>
                            <span className="font-extrabold text-lime text-20 mr-2">Sukces!</span>
                            <span className="font-bold text-black text-20">{success}</span>
                        </>
                    :
                        <>
                            <Icon icon="icon-park-solid:file-failed" width={25} height={25} className="text-danger mr-3"/>
                            <span className="font-extrabold text-danger text-20 mr-2">Błąd!</span>
                            <span className="font-bold text-black text-20">{error}</span>
                        </>
                    }
                </div>
        </Transition>
    )
}
export default SessionMessage;