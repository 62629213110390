const Links = [
  {url: 'dashboard', icon: 'material-symbols:border-all-rounded', text: 'Dashboard'},
  {url: 'warehouses', icon: 'mdi:house', text: 'Magazyny'},
  {url: 'agents', icon: 'carbon:user-avatar-filled', text: 'Agenci'},
  {url: 'pages', icon: 'ri:pages-fill', text: 'Strony'},
  {url: 'regions', icon: 'ic:baseline-place', text: 'Województwa'},
  {url: 'cities', icon: 'ic:baseline-place', text: 'Miasta'},
  {url: 'companies', icon: 'material-symbols:garage-home', text: 'Firmy'},
  {url: 'other-parameters', icon: 'material-symbols:settings-outline', text: 'Inne parametry'},
  {url: 'contact-forms', icon: 'mdi:envelope-outline', text: 'Kontakt'}
]

export default Links;